import { template as template_6f7eb6135b4e4d9d8c179d93d8643cbd } from "@ember/template-compiler";
const SidebarSectionMessage = template_6f7eb6135b4e4d9d8c179d93d8643cbd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
