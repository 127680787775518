import { template as template_b665d6ca7b0043f6b5654617e0098e65 } from "@ember/template-compiler";
const WelcomeHeader = template_b665d6ca7b0043f6b5654617e0098e65(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
