import { template as template_26029a1f9cb4410aa55523a5a6dea62a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_26029a1f9cb4410aa55523a5a6dea62a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
